var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ewm px-6"},[_c('esp-dx-data-grid',{ref:"subjectGrid",attrs:{"data-grid":_vm.dataGrid},on:{"cell-hover-changed":_vm.onCellHoverChanged,"editing-start":_vm.onEditingStart,"saving":e => _vm.onSaving(e)}}),_c('DxPopover',{attrs:{"width":"auto","visible":_vm.defaultVisible,"target":_vm.hoverTarget,"position":"top"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.popover_description)}})]),_c('modal-add-agent',{attrs:{"isOpen":_vm.modal.agent.popupVisible,"useSaveBtn":_vm.canEdit,"showModalTitle":true,"selectedIdList":_vm.modal.agent.selectedIdList},on:{"closeModal":function($event){return _vm.onClose(false)},"saveModal":_vm.onSave}}),_c('DxPopup',{attrs:{"show-title":true,"title":_vm.modal.initData ? _vm.modal.initData.title : null,"min-width":_vm.modal.initData ? _vm.modal.initData.width : null,"width":_vm.modal.initData ? _vm.modal.initData.width : null,"min-height":_vm.modal.initData ? _vm.modal.initData.height : null,"height":_vm.modal.initData ? _vm.modal.initData.height : null,"drag-enabled":true,"resize-enabled":true,"show-close-button":true,"close-on-outside-click":false,"visible":_vm.modal.isOpened},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',[_c(_vm.modal.currentComponent,{tag:"component",attrs:{"contentData":_vm.modal.contentData},model:{value:(_vm.modal.contentData),callback:function ($$v) {_vm.$set(_vm.modal, "contentData", $$v)},expression:"modal.contentData"}})],1)]},proxy:true}]),model:{value:(_vm.modal.isOpened),callback:function ($$v) {_vm.$set(_vm.modal, "isOpened", $$v)},expression:"modal.isOpened"}},[_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":_vm.modal.initData.hasOwnProperty('buttons')
					? _vm.modal.initData.buttons.hasOwnProperty('save')
						? _vm.modal.initData.buttons.hasOwnProperty('save')
						: false
					: false,"options":{
				elementAttr: {
					class: 'default filled txt_S medium',
				},
				text: _vm.modal.initData.hasOwnProperty('buttons')
					? _vm.modal.initData.buttons.hasOwnProperty('save')
						? _vm.modal.initData.buttons.save.text
						: ''
					: '',
				width: _vm.modal.initData.hasOwnProperty('buttons')
					? _vm.modal.initData.buttons.hasOwnProperty('save')
						? _vm.modal.initData.buttons.save.width
						: 120
					: 120,
				height: _vm.modal.initData.hasOwnProperty('buttons')
					? _vm.modal.initData.buttons.hasOwnProperty('save')
						? _vm.modal.initData.buttons.save.height
						: 40
					: 40,
				onClick: () => {
					_vm.onConfirmModal();
				},
			}}}),_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":_vm.modal.initData.hasOwnProperty('buttons')
					? _vm.modal.initData.buttons.hasOwnProperty('cancel')
						? _vm.modal.initData.buttons.hasOwnProperty('cancel')
						: !_vm.modal.initData.buttons.hasOwnProperty('cancel')
					: false,"options":{
				elementAttr: {
					class: 'white filled txt_S medium',
				},
				text: _vm.modal.initData.hasOwnProperty('buttons')
					? _vm.modal.initData.buttons.hasOwnProperty('cancel')
						? _vm.modal.initData.buttons.cancel.text
						: ''
					: '',
				width: _vm.modal.initData.hasOwnProperty('buttons')
					? _vm.modal.initData.buttons.hasOwnProperty('cancel')
						? _vm.modal.initData.buttons.cancel.width
						: 120
					: 120,
				height: _vm.modal.initData.hasOwnProperty('buttons')
					? _vm.modal.initData.buttons.hasOwnProperty('cancel')
						? _vm.modal.initData.buttons.cancel.height
						: 40
					: 40,
				onClick: () => {
					_vm.isOpenModal(false);
				},
			}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }