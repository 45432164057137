<template>
	<div class="ewm px-6">
		<esp-dx-data-grid
			:data-grid="dataGrid"
			ref="subjectGrid"
			@cell-hover-changed="onCellHoverChanged"
			@editing-start="onEditingStart"
			@saving="e => onSaving(e)"
		>
		</esp-dx-data-grid>

		<DxPopover width="auto" :visible="defaultVisible" :target="hoverTarget" position="top">
			<div v-html="popover_description"></div>
		</DxPopover>

		<!-- Agent Modal -->
		<modal-add-agent
			:isOpen="modal.agent.popupVisible"
			:useSaveBtn="canEdit"
			:showModalTitle="true"
			:selectedIdList="modal.agent.selectedIdList"
			@closeModal="onClose(false)"
			@saveModal="onSave"
		/>
		<!-- Agent Modal -->

		<!-- Modal Layer -->
		<DxPopup
			:show-title="true"
			:title="modal.initData ? modal.initData.title : null"
			:min-width="modal.initData ? modal.initData.width : null"
			:width="modal.initData ? modal.initData.width : null"
			:min-height="modal.initData ? modal.initData.height : null"
			:height="modal.initData ? modal.initData.height : null"
			:drag-enabled="true"
			:resize-enabled="true"
			:show-close-button="true"
			:close-on-outside-click="false"
			v-model="modal.isOpened"
			:visible="modal.isOpened"
		>
			<template #content>
				<div>
					<component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"></component>
				</div>
			</template>

			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="
					modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.hasOwnProperty('save')
							: false
						: false
				"
				:options="{
					elementAttr: {
						class: 'default filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.save.text
							: ''
						: '',
					width: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.save.width
							: 120
						: 120,
					height: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.save.height
							: 40
						: 40,
					onClick: () => {
						onConfirmModal();
					},
				}"
			/>
			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="
					modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.hasOwnProperty('cancel')
							: !modal.initData.buttons.hasOwnProperty('cancel')
						: false
				"
				:options="{
					elementAttr: {
						class: 'white filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.cancel.text
							: ''
						: '',
					width: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.cancel.width
							: 120
						: 120,
					height: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.cancel.height
							: 40
						: 40,
					onClick: () => {
						isOpenModal(false);
					},
				}"
			/>
		</DxPopup>
		<!-- /Modal Layer -->
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxPopover } from 'devextreme-vue/popover';
import { isSuccess } from '@/plugins/common-lib';

import ModalEducationList from '@/pages/ewm/education/implementation/schedule/modal-education-list.vue'; //강의목록
import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue"; //인사팝업

let vm = this;

export default {
	components: {
    EspDxDataGrid,
		DxButton,
		DxDateBox,
		DxPopover,
		DxPopup,
		DxToolbarItem,

		ModalEducationList,
		ModalAddAgent,
	},
	props: {
		server: Object,
	},
	watch: {},
	data() {
		return {
			containerElement: null,
			canEdit: this.server.processCd === this.$_enums.ewm.eduEducationProcess.READY.value,
			defaultVisible: false,
			popover_caption: '',
			popover_description: '',
			hoverTarget: '',
			codes: {
				typeCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
				placeCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
			},
			config: {
				stylingMode: 'outlined',
				textStylingMode: 'filled',
				updateYn: true,
			},
			attachFileUrl: {
				fileUpload: 'COMMON_ATTACHED_FILE_UPLOAD',
				fileDownload: 'COMMON_ATTACHED_FILE_DOWNLOAD',
				fileDelete: 'COMMON_ATTACHED_FILE_DELETE',
			},
			modal: {
				isOpened: false,
				currentComponent: null,
				initData: {},
				contentData: null,
				agent: {
					agtData: [],
					agtIdList: [],
					popupVisible: false,
					useSaveBtn: true,
					selectedIdList: [],
					rowData: [],
				},
			},
			subjectIdFilter: [],
			deleteData: [],
			dataGrid: {
				// keyExpr: 'id',
				refName: 'subjectGrid',
				allowColumnResizing: true, //컬럼 사이즈 허용
				columnResizingMode: 'widget',
				columnAutoWidth: true,
				showBorders: false, //border 유무
				showColumnHeaders: true, //컬럼 헤더 유무
				showColumnLines: false, //컬럼 세로선 유무
				showRowLines: true, //컬럼 가로선 유무
				rowAlternationEnabled: false,
				dataSource: [],
				// width:'200',     // 주석처리시 100%
				height: '280', // 주석처리시 100%
				scrolling: {
					mode: 'standard',
				},
				callApi: 'CALL_EWM_API',
				apiActionNm: {
					//select: 'EWM_EDUCATION_SUBJECT_LIST',
					//insert: "EWM_EDUCATION_SUBJECT_SAVE",
					//update: 'EWM_EDUCATION_SUBJECT_SAVE',
					//delete: 'EWM_EDUCATION_SUBJECT_DELETE',
				},
				customEvent: {
					cellHoverChanged: true,
					cellPrepared: false,
					editingStart: true,
					rowInserted: false,
					rowInserting: false,
					saving: true,
					rowPrepared: false,
					rowUpdated: true,
				},
				showActionButtons: {
					copy: false,
					select: false,
					update: false,
					delete: false,
					excel: false,
					customButtons: [
						{
							widget: 'dxButton',
							options: {
								icon: '',
								text: '추가',
								elementAttr: { class: 'btn_XS default filled add1' },
								width: 60,
								height: 30,
								disabled: !this.canEdit,
								onClick() {
									vm.onAddEdu();
								},
							},
							location: 'before',
							sortIndex: 10,
						},
						{
							widget: 'dxButton',
							options: {
								icon: '',
								text: '삭제',
								elementAttr: { class: 'btn_XS white light_filled trash' },
								width: 60,
								height: 30,
								disabled: !this.canEdit,
								onClick() {
									vm.onDeleteData();
								},
							},
							location: 'before',
						},
					],
				},
				grouping: {
					contextMenuEnabled: false,
					autoExpandAll: false,
					allowCollapsing: true,
					expandMode: 'rowClick', // rowClick or buttonClick
				},
				groupPanel: {
					visible: false,
				},
				columnChooser: {
					enabled: false, // 컬럼 Chooser 버튼 사용유무
				},
				loadPanel: {
					enabled: true, // 로딩바 표시 유무
				},
				remoteOperations: {
					// 서버사이드 여부
					filtering: false,
					sorting: false,
					grouping: false,
					paging: false,
				},
				paging: {
					enabled: false,
					pageSize: 10,
					pageIndex: 0, // 시작페이지
				},
				pager: {
					visible: false, //페이저 표시 여부
					showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
					allowedPageSizes: [],
					displayMode: 'compact', //표시 모드 : ['full', 'compact']
					showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
					showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
				},
				filterRow: {
					visible: false,
					operationDescriptions: {
						contains: '포함',
					},
				},
				headerFilter: {
					visible: false,
				},
				editing: {
					allowUpdating: this.canEdit,
					allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
					allowDeleting: false,
					mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
					startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
					selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
				},
				selecting: {
					mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
					selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
					showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
				},
				columns: [
					{
						dataField: 'scheId',
						height: 10,
						visible: false,
					},
					{
						dataField: 'subjId',
						height: 10,
						visible: false,
					},
					{
						caption: '강의명',
						dataField: 'eduNm',
						height: 40,
						alignment: 'center',
						allowEditing: false,
						allowSorting: false,
					},
					{
						caption: '진행방식',
						dataField: 'divisionCd',
						width: 90,
						height: 40,
						alignment: 'center',
						allowEditing: false,
						allowSorting: false,
						lookup: {
							dataSource: this.$_enums.ewm.eduEducationProcessDivision.values,
							displayExpr: 'label',
							valueExpr: 'value',
						},
					},
					{
						caption: '강의시간',
						dataField: 'eduTime',
						dataType: 'number',
						width: 90,
						height: 40,
						alignment: 'center',
						allowEditing: false,
						allowSorting: false,
						calculateDisplayCellValue: rowData => {
							return `${vm.pad(Math.floor(rowData.eduTime / 60), 2)}:${vm.pad(rowData.eduTime % 60, 2)}`;
						},
					},
					{
						caption: '강의유형',
						dataField: 'typeCd',
						width: 100,
						height: 40,
						alignment: 'center',
						allowEditing: false,
						allowSorting: false,
						lookup: {},
					},
					{
						caption: '강사 [ID]',
						dataField: 'teachers',
						width: 110,
						height: 40,
						alignment: 'center',
						allowEditing: false,
						allowSorting: false,
						cellTemplate: (container, options) => {
							let aTag = document.createElement('div');
							if (!options.data.teachers) {
								aTag.innerText = '';
							} else if (options.data.teachers.length === 1) {
								aTag.innerText = `${options.data.teachers[0].teacherName}[${options.data.teachers[0].teacherId}]`;
							} else if (options.data.teachers.length > 1) {
								aTag.innerText = `${options.data.teachers.length}명`;
							} else {
								aTag.innerText = '';
							}
							container.append(aTag);
						},
					},
					{
						caption: '대상자관리',
						dataField: 'targetPerson',
						width: 100,
						height: 40,
						alignment: 'center',
						allowEditing: false,
						allowSorting: false,
						cellTemplate: (container, options) => {
							let aTag = document.createElement('a');
							if (!options.data.targetPerson) {
								aTag.innerText = `0명`;
							} else {
								aTag.innerText = `${options.data.targetPerson}명`;
							}
							aTag.addEventListener('click', () => {
								vm.onOpen(options.data);
							});
							container.append(aTag);
						},
					},
					{
						caption: '시작일',
						dataField: 'eduDt',
						dataType: 'date',
						width: 110,
						height: 40,
						alignment: 'center',
						allowSorting: false,
						allowEditing: true,
						editorOptions: {
							maxLength: '10',
							max: this.server.ymdEnd,
							min: this.server.ymdStart,
						},
						requiredRule: {
							message: '필수 항목입니다. 교육기간 내로 입력하세요.',
						},
					},
					{
						caption: '시작시간',
						dataField: 'subjectTimeStart',
						width: 90,
						height: 40,
						alignment: 'center',
						allowEditing: true,
						allowSorting: false,
						calculateDisplayCellValue: rowData => {
							if (!rowData.subjectTimeStart) {
								return (rowData.subjectTimeStart = null);
							} else if (rowData.subjectTimeStart.length == 6) {
								rowData.subjectTimeStart = rowData.subjectTimeStart.substring(0, 4);
							}

							return this.$_commonlib.formatDate(rowData.subjectTimeStart, 'HHmm', 'HH:mm');
						},
						editorOptions: {
							maxLength: '4',
						},
						patternRule: {
							pattern: /^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/,
							message: '24시간제 시간으로 입력하세요(0000~2359)',
						},
						requiredRule: {
							message: '필수 항목입니다.',
						},
					},
					{
						caption: '종료시간',
						dataField: 'subjectTimeEnd',
						width: 90,
						height: 40,
						alignment: 'center',
						allowEditing: false,
						allowSorting: false,
						calculateDisplayCellValue: rowData => vm.calculateEduSubEndTime(rowData),
					},
					{
						caption: '첨부파일',
						dataField: 'attacheFiles',
						width: 90,
						height: 40,
						alignment: 'center',
						allowEditing: false,
						allowSorting: false,
						cellTemplate: (container, options) => {
							if (!options.data.attacheFiles) {
								let aTag = document.createElement('div');
								aTag.innerText = `-`;
								container.append(aTag);
							} else if (options.data.attacheFiles.length > 0) {
								let button = new DxButton({
									propsData: {
										icon: 'download',
										value: options.value,
										onClick: () => {
											vm.downloadFile(options.data);
										},
									},
								});
								button.$mount();
								container.append(button.$el);
							}
						},
					},
					{
						caption: '교육장소',
						dataField: 'placeCd',
						width: 100,
						height: 40,
						alignment: 'center',
						allowEditing: true,
						allowSorting: false,
						lookup: {},
						// customRule: {
						// 	message: '필수 항목입니다.',
						// 	callback: options => {
						// 		if (options.data.divisionCd === this.$_enums.ewm.eduEducationProcessDivision.ONLINE.value) {
						// 			return true;
						// 		} else {
						// 			return !this.$_commonlib.isEmpty(options.data.placeCd);
						// 		}
						// 	},
						// },
						/* requiredRule: {
							message: '필수 항목입니다.',
						}, */
					},
				],
			},
		};
	},
	computed: {
		ewmCodes() {
			return this.$_enums.ewm;
		},
	},
	methods: {
		pad(num, digit) {
			return num.toString().padStart(digit, '0');
		},
		/** @description : 강사컬럼 마우스 hover 이벤트 */
		onCellHoverChanged(e) {
			this.defaultVisible = false;
			this.hoverTarget = e.cellElement;
			if (e.rowType === 'data' && e.eventType === 'mouseover') {
				if (e.column.dataField == 'teachers') {
					if (e.data.teachers.length > 0) {
						let teacherText = '';
						e.data.teachers.forEach(val => (teacherText += `<li>${val.teacherName}[${val.teacherId}]</li>`));
						this.popover_caption = '강사';
						this.popover_description = `<ul class="teacher-list">${teacherText}</ul>`;
						this.defaultVisible = true;
					}
				}
			}
		},
		onEditingStart(e) {
			if (e.column.dataField === 'placeCd') {
				if (e.data.divisionCd === this.$_enums.ewm.eduEducationProcessDivision.ONLINE.value) {
					e.cancel = true;
				}
			}
		},
		/** @description : 저장 */
		async onSaving(e) {
			e.cancel = true;

			if (!e.changes.length) {
				return;
			}

			let data = [];
			let totalTime = 0;

			if (e.changes && e.changes.length > 0) {
				e.changes.forEach(d => {
					if (d.type === 'update') {
						let rowIndex = e.component.getRowIndexByKey(d.key);
						let row = e.component.getVisibleRows()[rowIndex];
						let updateData = row.data;

						data.push(updateData);
					} else if (d.type === 'insert') {
						if (Object.keys(d.data).length !== 0) {
							let updateData = { ...d.data };
							updateData['id'] = null;
							updateData['scheId'] = this.server.id;

							data.push(updateData);
						}
					}
				});
			}

			data.forEach(f => {
				if (f.subjectTimeStart.length === 4) {
					f.subjectTimeStart = f.subjectTimeStart + '00';
				}
			});

			const groupedData = data.reduce((acc, curr) => {
				//console.log('curr.eduDt', curr.eduDt);
				//const eduDt = curr.eduDt.toDateString();
				const eduDt = curr.eduDt;
				if (!acc[eduDt]) {
					acc[eduDt] = [];
				}
				acc[eduDt].push(curr);
				return acc;
			}, {});
			for (const group of Object.values(groupedData)) {
				for (let i = 0; i < group.length; i++) {
					const { subjectTimeStart: start1, subjectTimeEnd: end1 } = group[i];
					for (let j = i + 1; j < group.length; j++) {
						const { subjectTimeStart: start2, subjectTimeEnd: end2 } = group[j];

						if ((start1 >= start2 && start1 < end2) || (start2 >= start1 && start2 < end1)) {
							console.log('시간이 겹칩니다:', group[i], group[j]);
							return this.$_Msg(`강의 시간이 겹칩니다.`);
						}
					}
				}
			}

			if (!(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), { title: `<h2>알림</h2>` }))) {
				return;
			}

			e.component.getVisibleRows().forEach(row => {
				console.log('t.eduTime', row.data.eduTime);
				totalTime += row.data.eduTime;
			});

			const payload = {
				actionname: 'EWM_EDUCATION_SCHEDULE_SUBJECT_REG_SAVE',
				data: {
					data: data,
					totalEduTime: totalTime,
				},
				loading: true,
			};
			const res = await this.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
				this.selectDataList();

				this.server.eduTime = totalTime;
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description : 삭제 */
		async onDeleteData() {
			if (!this.config.updateYn) {
				this.$_Msg(`진행 중인 교육입니다.`);
				return;
			}

			const selectedRowsData = this.$refs.subjectGrid.selectedRowsData;

			if (!selectedRowsData?.length) {
				this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			}

			if (!(await this.$_Confirm(this.$_msgContents('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
				return;
			}

			const sendData = selectedRowsData.map(x => x.id);
			const filterData = this.dataGrid.dataSource.filter(f => !sendData.includes(f.id));

			let totalTime = 0;
			filterData.forEach(row => {
				totalTime += row.eduTime;
			});

			const payload = {
				actionname: 'EWM_EDUCATION_SCHEDULE_SUBJECT_REG_DELETE',
				data: {
					ids: sendData,
					scheId: filterData[0].scheId,
					totalEduTime: totalTime,
				},
				loading: true,
			};

			const res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
				this.selectDataList();

				this.server.eduTime = totalTime;
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		onOpenModal(componentNm, componentInitData, data) {
			this.modal.currentComponent = componentNm;
			this.modal.initData = componentInitData;
			this.modal.contentData = data;
			this.isOpenModal(true);
		},
		/** @description: 팝업 오픈 체크 메서드 */
		isOpenModal(data) {
			this.modal.isOpened = data;
			if (!data) {
				this.modal.currentComponent = null;
				this.modal.initData = {};
			}
		},
		/** @description: 강의 추가 */
		onAddEdu() {
			if (!this.server.id) {
				this.$_Msg(`계획 저장 후 강의를 선택 할 수 있습니다.`);
				return;
			}
			if (!this.config.updateYn) {
				this.$_Msg(`진행 중인 교육입니다.`);
				return;
			}

			console.log('this.subjectIdFilter', this.subjectIdFilter);
			this.subjectIdFilter = this.$refs.subjectGrid.getGridInstance.getVisibleRows().map(row => row.data.subjId);

			console.log('this.subjectIdFilter', this.subjectIdFilter);

			this.onOpenModal(
				'ModalEducationList',
				{
					title: '강의 추가',
					buttons: {
						save: { text: '선택' },
						cancel: { text: '닫기' },
					},
					width: '1200',
					height: '600',
				},
				this.subjectIdFilter,
			);
		},
		/** @description: 강의 row 추가 */
		async onConfirmModal() {
			let obejctArray = [];
			this.modal.contentData.forEach(i => {
				if (!this.subjectIdFilter.includes(i.subjId)) {
					obejctArray.push(i);
				}
			});

			let loopIdx = 0;
			while (loopIdx < obejctArray.length) {
				this.$refs.subjectGrid.getGridInstance.addRow();
				loopIdx++;
			}

			obejctArray.forEach((obj, index) => {
				Object.entries(obj).forEach(([key, value]) => {
					this.$refs.subjectGrid.getGridInstance.cellValue(index, key, value);
					// console.log(key, value);
				});
			});

			//this.subjectIdFilter = this.modal.contentData.map(item => item.subjId);

			this.isOpenModal(false);
		},
		/** @description: 대상자 추가 */
		async onOpen(data) {
			console.log('대상자관리 클릭');
			console.log('this.canEdit', this.config.updateYn);

			if (!data.id) {
				this.$_Msg(`저장 후 대상자를 선택 하세요.`);
				return;
			}

			this.modal.agent.rowData = data;
			const payload = {
				actionname: 'EWM_EDUCATION_SCHEDULE_SUBJECT_REG_TARGET_LIST',
				data: {
					subjectRegId: data.id,
				},
				loading: false,
			};
			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.modal.agent.agtData = res.data.data;
				this.modal.agent.agtIdList = res.data.data.map(item => item.agtId);

				this.modal.agent.selectedIdList = this.modal.agent.agtIdList;
				this.modal.agent.useSaveBtn = this.config.updateYn;
				this.modal.agent.popupVisible = true;
				console.log('this.modal.agent.useSaveBtn', this.modal.agent.useSaveBtn);
			} else {
				return () => {
					throw 'Data Loading Error';
				};
			}
		},
		onClose() {
			this.modal.agent.popupVisible = false;
			this.modal.agent.selectedIdList = [];
		},
		/** @description: 대상자 저장 */
		async onSave(value) {
			// console.log(value)
			if (!this.canEdit) {
				return;
			}

			if (!value.length) {
				return;
			}

			let currentTargets = this.modal.agent.agtData;
			currentTargets.forEach(t => {
				if (value.findIndex(v => v.agtid == t.agtId) < 0) {
					t.delFl = 'Y';
				} else {
					t.delFl = 'N';
				}
			});
			let sendData = currentTargets.filter(t => t.delFl == 'Y');
			currentTargets = currentTargets.filter(t => t.delFl != 'Y');

			const selectedTeachers = [];
			value.forEach(v => {
				if (currentTargets.findIndex(t => v.agtid == t.agtId) < 0) {
					selectedTeachers.push({
						id: null,
						scheId: this.modal.agent.rowData.scheId,
						subjectRegId: this.modal.agent.rowData.id,
						agtId: v.agtid,
						joinCd: 980,
						delFl: 'N',
					});
				}
			});

			sendData = [...sendData, ...selectedTeachers];

			const payload = {
				actionname: 'EWM_EDUCATION_SCHEDULE_SUBJECT_REG_TARGET_SAVE',
				data: { data: sendData },
				loading: true,
			};
			const res = await this.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
				this.selectDataList();
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
			this.onClose();
		},
		/** @description: 파일 다운로드 */
		downloadFile(data) {
			// console.log(data)
			const fileGroupId = data.attacheFiles[0].groupId;
			const fileName = data.attacheFiles[0].fileName;

			const token = this.$store.getters.getAccessToken;

			const downloadUrl = `${this.attachFileUrl.fileDownload}/${fileGroupId}/${fileName}`;

			const requestOptions = {
				method: 'GET',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			};

			fetch(downloadUrl, requestOptions)
				.then(response => {
					if (!response.ok) {
						this.$_Toast('다운로드 오류 발생');
						throw new Error('response error');
					}
					return response.blob();
				})
				.then(blob => {
					const url = window.URL.createObjectURL(blob);

					// console.log('url', url);
					const a = document.createElement('a');
					a.href = url;
					a.download = data.attacheFiles[0].originalFileFullName;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					window.URL.revokeObjectURL(url);
				})
				.catch(error => {
					// console.error('file download error:', error);
					this.$_Toast('다운로드 오류 발생:' + error);
				});
		},
		calculateEduSubEndTime(rowData) {
			if (rowData.subjectTimeStart) {
				const startTime = rowData.subjectTimeStart;
				const eduTime = rowData.eduTime;

				const startHour = parseInt(startTime.substring(0, 2));
				const startMinute = parseInt(startTime.substring(2, 4));

				const endTimeMinutes = startHour * 60 + startMinute + eduTime;
				const endHour = Math.floor(endTimeMinutes / 60);
				const endMinute = endTimeMinutes % 60;
				const pad = num => num.toString().padStart(2, '0');

				rowData.subjectTimeEnd = pad(endHour) + '' + pad(endMinute) + '00';
				// console.log('끝나는시간 변경',rowData.subjectTimeEnd);
				return this.$_commonlib.formatDate(rowData.subjectTimeEnd, 'HHmm', 'HH:mm');
				// return `${pad(endHour)}:${pad(endMinute)}`;
			} else {
				return;
			}
		},
		/** @description : 데이터 검색 메서드 */
		async selectDataList() {
			console.log('canEdit', this.canEdit);
			const payload = {
				actionname: 'EWM_EDUCATION_SCHEDULE_SUBJECT_REG_LIST',
				data: {
					scheId: this.server.id,
				},
				loading: false,
			};

			const res = await vm.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				// console.log('process', this.server.processCd)
				if (this.canEdit) {
					this.config.updateYn = true;
				} else {
					this.config.updateYn = false;
				}
				// console.log(this.config.updateYn)
				// console.log('초기 데이터',res.data.data)
				this.dataGrid.dataSource = res.data.data;

				if (this.canEdit) {
					this.$refs.subjectGrid.getGridInstance.cancelEditData();
				}
				//this.subjectIdFilter = res.data.data.map(item => item.subjId);
				// console.log('초기 필터',this.subjectIdFilter)
			} else {
				return () => {
					throw 'Data Loading Error';
				};
			}
		},
		/** @description : 코드 로드 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_edu_education_type,root_ewm_edu_education_place');
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		createdData() {
			vm = this;

			this.canEdit = this.server.processCd === this.$_enums.ewm.eduEducationProcess.READY.value;

			this.initCodeMap().then(() => {
				this.codes.typeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_education_type'], 2);
				this.codes.placeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_education_place'], 2);

				this.$refs.subjectGrid.getGridInstance.columnOption('typeCd', 'lookup', this.codes.typeCd);
				this.$refs.subjectGrid.getGridInstance.columnOption('placeCd', 'lookup', this.codes.placeCd);
			});

			this.dataGrid.showActionButtons.customButtons[0].options.disabled = !this.canEdit;
			this.dataGrid.showActionButtons.customButtons[1].options.disabled = !this.canEdit;
			this.dataGrid.editing.allowUpdating = this.canEdit;

			this.selectDataList();
		},
		/** @description : 라이프사이클 mounted시 호출되는 메서드 */
		mountData() {
			const apiFileUpload = this.$store.getters.getRoutingInfos.find(d => d.actionNm === 'COMMON_ATTACHED_FILE_UPLOAD');
			const apiFileDownload = this.$store.getters.getRoutingInfos.find(d => d.actionNm === 'COMMON_ATTACHED_FILE_DOWNLOAD');
			const apiFileDelete = this.$store.getters.getRoutingInfos.find(d => d.actionNm === 'COMMON_ATTACHED_FILE_DELETE');

			this.attachFileUrl.fileUpload = apiFileUpload.host + apiFileUpload.path;
			this.attachFileUrl.fileDownload = apiFileDownload.host + apiFileDownload.path;
			this.attachFileUrl.fileDelete = apiFileDelete.host + apiFileDelete.path;

			this.containerElement = document.getElementById('containerElement');
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountData();
	},
};
</script>
